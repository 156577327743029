<template>
    <sections-base
        class="mission-teaser"
        background-class="bg-ocean-600"
        inner-class="py-big"
    >
        

        <div ref="content" class="content">
            <div class="copy">
                <header class="">
                    <h1>Unsere Mission</h1>
                </header>
                <p>
                    Durch die Wiederverwendung und das Recycling von Wertstoffen
                    können wir eine ganze Menge CO2 einsparen! Indem wir
                    Wertstoffe zurück in den Kreislauf bringen, reduzieren wir
                    ihre Neuproduktion und bewahren sie vor der Entsorgung.
                </p>

                <p class="mission-text">
                    “We ease and accelerate the world's transition to a circular
                    future by offering smart & sustainable solutions to recover
                    the value of resources & goods.”
                </p>

                <butty class="accent" :to="{ name: 'team' }"
                    >Mission kennenlernen</butty
                >
            </div>

            <div :class="['hero-image-container', { show }]">
                <icon name="scribbles-pow" class="pow1" />
                <icon name="scribbles-star1" class="" />
                <icon name="scribbles-star2" class="" />
                <icon name="scribbles-pow" class="pow2" />

                <picture>
                    <source srcset="/images/hero2.webp" type="image/webp" />
                    <img
                        class="hero-image"
                        src="/images/hero2.png"
                        width="401"
                        height="745"
                        loading="lazy"
                        alt="Eine recyclehero Heldin trägt eine Altglas-Box."
                    />
                </picture>
            </div>
        </div>
    </sections-base>
</template>

<script>
export default {
    data() {
        return {
            show: false,
        }
    },

    mounted() {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry) {
                    if (entry.intersectionRatio >= 0.5) {
                        this.show = true
                    } else if (entry.intersectionRatio <= 0) {
                        this.show = false
                    }
                }
            },
            { threshold: [0, 0.5] },
        )
        observer.observe(this.$refs.content)
    },
}
</script>

<style lang="scss" scoped>
:deep(.section-base-background) {
    overflow: hidden;

    .icon {
        position: absolute;
        display: block;
    }

    .cargo-bike {
        left: 94%;
        top: 9%;
        width: 15%;
        transform: translate(-50%, -50%) rotate(15deg);
        color: theme('colors.slate.200');
    }
}

.content {
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    z-index: 10;
    min-height: 22rem;
    overflow: hidden;
    background-color: theme('colors.slate.900');
    color: #fff;
    padding: 1rem;

    header {
        text-transform: uppercase;
        margin: 0 0 1rem;
    }

    .copy {
        position: relative;
        z-index: 10;
        width: 100%;
        height: auto;
        display: flex;
        flex-flow: column nowrap;
        justify-items: flex-start;
    }

    p {
        @apply text-md;
        // width: 80%;
        margin: 0 0 2.5rem;
    }

    .butty {
        margin-right: auto;
        @apply text-sm;
    }
}

.hero-image-container {
    display: none;
    position: absolute;
    z-index: 5;
    bottom: 0;
    right: -5%;
    width: 55%;
    height: auto;
    pointer-events: none;
    user-select: none;

    &.show {
        .hero-image {
            opacity: 1;
            transform: translate(0%, 0%) scale(1);
        }

        .icon {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);

            &.pow2 {
                transform: translate(-50%, -50%) rotate(76deg) scale(1);
            }
        }
    }

    .icon {
        position: absolute;
        display: block;
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.5);
        transition: opacity 0.3s 0s,
            transform 0.3s 0s theme('transitionTimingFunction.easeOutCubic');
    }

    .pow1 {
        left: 17%;
        top: 31%;
        width: 9%;
        transition-delay: 0.3s, 0.3s;
    }

    .scribbles-star1 {
        left: 23%;
        top: 13%;
        width: 10%;
        transition-delay: 0.45s, 0.45s;
    }

    .scribbles-star2 {
        left: 73%;
        top: 8%;
        width: 11%;
        transition-delay: 0.6s, 0.6s;
    }

    .pow2 {
        left: 84%;
        top: 31%;
        width: 9%;
        transform: translate(-50%, -50%) rotate(76deg) scale(0.5);
        transition-delay: 0.15s, 0.15s;
    }
}

.hero-image {
    display: block;
    width: 62%;
    height: auto;
    margin: 0 auto;
    opacity: 0.01;
    transform: translate(0%, 15%) scale(0.85);
    transition: opacity 0.3s 0s,
        transform 0.3s 0s theme('transitionTimingFunction.easeOutCubic');
}

.mission-text {
    line-height: 1.4;
    color: #fff;
    border-left: 0.5rem solid theme('colors.primary-light');
    padding: 0.25rem 0 0.25rem 1.75rem;
    margin: 0 0 1rem;
    font-style: italic;
}

@media screen and (min-width: 400px) {
    .content {
        .copy {
            width: 80%;
        }
    }

    .hero-image-container {
        width: 55%;
    }
}

@media screen and (min-width: 490px) {
    .content {
        .copy {
            width: 65%;
        }
    }

    .hero-image-container {
        width: 55%;
    }
}

@screen sm {
    :deep(.section-base-background) {
        .cargo-bike {
            left: 94%;
            top: 9%;
            width: 11%;
        }
    }

    .content {
        width: 95%;
        min-height: 22rem;
        padding: 1rem 1.5rem;

        header {
            margin: 0 0 2rem;
        }

        .copy {
            width: 100%;
        }

        p {
            @apply text-lg;
            width: 60%;
            margin: 0 0 3rem;
        }
    }

    .hero-image-container {
        display: block;
        bottom: 0;
        right: 0%;
        width: 44%;
        padding: 0;
    }
}

@screen md {
    :deep(.section-base-background) {
        .cargo-bike {
            left: 92%;
            top: 10%;
            width: 9%;
        }
    }

    .content {
        width: 90%;
        align-items: stretch;

        .copy {
            width: 65%;
        }

        p {
            width: 100%;
        }
    }

    .hero-image-container {
        width: 36%;
    }
}

@screen lg {
    :deep(.section-base-background) {
        .cargo-bike {
            left: 89%;
            top: 12%;
            width: 8%;
        }
    }

    .content {
        padding: 2rem 3rem;
    }

    .hero-image-container {
        width: 38%;
        transform: translate(0, 15%);
    }
}

@screen xl {
    :deep(.section-base-background) {
        .cargo-bike {
            left: 84%;
            top: 12%;
            width: 7%;
        }
    }

    .content {
        width: 75%;
    }

    .hero-image-container {
        width: 42%;
        transform: translate(0, 25%);
    }
}
</style>
